
import { CategoryService } from '@/bll/service/CategoryService'
import { useCategoryStore } from '@/stores/category'
import { Options, Vue } from 'vue-class-component'
import Logger from '@/util/logger'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {
        id: String
    },
    emits: [],
})
export default class CategoryDelete extends Vue {
    private logger = new Logger(CategoryDelete.name)
    id!: string
    categoriesStore = useCategoryStore()
    categoryService = new CategoryService()

    errorMsg: string | null = null

    submitClicked (): void {
        this.logger.info('submitClicked')

        // this.categoryService.delete(this.id).then(() => {
        //     router.push('/')
        // })

        /*
        if (res.status >= 300) {
            this.errorMsg = res.status + ' ' + res.errorMsg
        } else {
            this.categoriesStore.$state.categories =
                await this.categoryService.getAll()

            this.$router.push('/categories')
        }
         */
    }
}
