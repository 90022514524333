
import { Options, Vue } from 'vue-class-component'
import { useReviewStore } from '@/stores/review'
import { ReviewService } from '@/bll/service/ReviewService'
import Logger from '@/util/logger'
import { IdentityService } from '@/bll/service/IdentityService'
import Header from '@/components/Header.vue'
import router from '@/router'
import { IReview } from '@/dal/domain/IReview'
import { useProductStore } from '@/stores/product'
import { ProductService } from '@/bll/service/ProductService'
import Vue3StarRatings from 'vue3-star-ratings'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {
        Header,
        Vue3StarRatings,
    },
    props: {
        id: Number,
    },
    emits: [],
})
export default class ReviewCreate extends Vue {
    private logger = new Logger(ReviewCreate.name)
    id!: number

    reviewStore = useReviewStore()
    reviewService = new ReviewService()
    productStore = useProductStore()
    productService = new ProductService()
    private identityService = new IdentityService()

    score: number | undefined = undefined
    description: string | undefined = undefined
    errorMsg: string | null = null

    async submitClicked (): Promise<void> {
        this.logger.info('submitClicked')

        const reviewInfo: IReview = {
            productId: this.id,
            score: this.score,
            description: this.description,
        }
        this.reviewService.add(reviewInfo).then((item) => {
            if (item.errorMsg !== undefined) {
                this.errorMsg = item.errorMsg
            } else {
                if (item.data) {
                    this.reviewStore.add(item.data)
                }
                router.push('/review')
            }
        })
    }

    get isAuthenticated (): boolean {
        return this.identityService.isAuthenticated()
    }

    mounted () {
        this.reviewService.get(this.id).then((item) => {
            if (item.errorMsg !== undefined) {
                this.reviewStore.$state.review = {}
            } else {
                if (item.data) {
                    this.reviewStore.$state.review = item.data
                }
            }
        })
        this.productService.getById(this.id).then((item) => {
            if (item.errorMsg !== undefined) {
                this.errorMsg = item.errorMsg
            } else {
                if (item.data) {
                    this.productStore.$state.product = item.data
                }
            }
        })
    }

    getProduct () {
        return this.productStore.$state.product
    }

    getReview () {
        return this.reviewStore.$state.review
    }
}
