
import { OfferService } from '@/bll/service/OfferService'
import { Options, Vue } from 'vue-class-component'
import { useIdentityStore } from '@/stores/identity'
import { useOfferStore } from '@/stores/offer'
import Logger from '@/util/logger'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {},
    emits: [],
})
export default class OfferIndex extends Vue {
    private logger = new Logger(OfferIndex.name)
    merchandisesStore = useOfferStore()
    merchandiseService = new OfferService()
    private identityStore = useIdentityStore()

    get isAuthenticated (): boolean {
        return this.identityStore.getJwt() !== null
    }

    get isAdmin (): boolean {
        return this.identityStore.isAdmin()
    }

    limitArray () {
        return this.merchandisesStore.$state.offers
    }

    async mounted (): Promise<void> {
        this.logger.info('mounted')
        // this.merchandisesStore.$state.offers =
        //     await this.merchandiseService.getAll()
    }
}

