import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", null, "Details customer " + _toDisplayString(_ctx.id), 1),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createVNode(_component_RouterLink, {
            to: { name: 'customers-details', params: { id: _ctx.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode("Details")
            ]),
            _: 1
          }, 8, ["to"]),
          _createTextVNode(" | "),
          _createVNode(_component_RouterLink, {
            to: { name: 'customers-edit', params: { id: _ctx.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode("Edit")
            ]),
            _: 1
          }, 8, ["to"]),
          _createTextVNode(" | "),
          _createVNode(_component_RouterLink, {
            to: { name: 'customers-delete', params: { id: _ctx.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode("Delete")
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_RouterLink, { to: { name: 'customers' } }, {
        default: _withCtx(() => [
          _createTextVNode("Back to Customers")
        ]),
        _: 1
      })
    ])
  ], 64))
}