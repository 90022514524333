
import { AccountService } from '@/bll/service/AccountService'
import { useAccountStore } from '@/stores/account'
import { Options, Vue } from 'vue-class-component'
import Logger from '@/util/logger'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {},
    emits: [],
})
export default class AccountIndex extends Vue {
    private logger = new Logger(AccountIndex.name)
    accountsStore = useAccountStore()
    accountService = new AccountService()

    async mounted (): Promise<void> {
        this.logger.info('mounted')
        // this.accountsStore.$state.accounts =
        //     await this.accountService.getAll()
    }
}

