
import { CustomerService } from '@/bll/service/CustomerService'
import { useCustomerStore } from '@/stores/customer'
import { Options, Vue } from 'vue-class-component'
import Logger from '@/util/logger'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {},
    emits: [],
})
export default class CustomerIndex extends Vue {
    private logger = new Logger(CustomerIndex.name)
    customersStore = useCustomerStore()
    customerService = new CustomerService()

    async mounted (): Promise<void> {
        this.logger.info('mounted')
        // this.customersStore.$state.customers =
        //     await this.customerService.getAll()
    }
}

