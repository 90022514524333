
import { Options, Vue } from 'vue-class-component'
import { RoleService } from '@/bll/service/RoleService'
import { useRoleStore } from '@/stores/role'
import Logger from '@/util/logger'
import { IRole } from '@/dal/domain/IRole'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {},
    emits: [],
})
export default class RoleIndex extends Vue {
    private logger = new Logger(RoleIndex.name)

    wishesStore = useRoleStore()
    wishService = new RoleService()

    async mounted (): Promise<void> {
        this.logger.info('mounted')
        const items = await this.wishService.getAll()
        this.wishesStore.$state.roles = items.data as IRole[]
    }
}

