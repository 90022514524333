
import { OfferService } from '@/bll/service/OfferService'
import { Options, Vue } from 'vue-class-component'
import { useOfferStore } from '@/stores/offer'
import Logger from '@/util/logger'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {
        id: String
    },
    emits: [],
})
export default class OfferDetails extends Vue {
    private logger = new Logger(OfferDetails.name)
    id!: string
    merchandisesStore = useOfferStore()
    merchandiseService = new OfferService()

    async mounted (): Promise<void> {
        this.logger.info('mounted')
        // this.merchandisesStore.$state.offer =
        //     await this.merchandiseService.get(this.id)
    }
}

