
import { AlarmService } from '@/bll/service/AlarmService'
import { useAlarmStore } from '@/stores/alarm'
import { Options, Vue } from 'vue-class-component'
import Logger from '@/util/logger'
import Header from '@/components/Header.vue'
import { IdentityService } from '@/bll/service/IdentityService'
import router from '@/router'
import { IAlarm } from '@/dal/domain/IAlarm'
import { ProductService } from '@/bll/service/ProductService'
import { useProductStore } from '@/stores/product'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {
        Header,
    },
    props: {
        id: Number,
    },
    emits: [],
})
export default class AlarmCreate extends Vue {
    private logger = new Logger(AlarmCreate.name)
    id!: number
    alarmStore = useAlarmStore()
    productStore = useProductStore()
    alarmService = new AlarmService()
    productService = new ProductService()
    private identityService = new IdentityService()

    minValue: number | undefined = undefined
    minStep: number | undefined = undefined
    errorMsg: string | null = null

    mounted () {
        this.productService.getById(this.id).then((item) => {
            if (item.errorMsg !== undefined) {
                this.productStore.$state.product = {}
            } else {
                if (item.data) {
                    this.productStore.$state.product = item.data
                }
            }
        })
    }

    async submitClicked (): Promise<void> {
        this.logger.info('submitClicked')

        const alarmInfo: IAlarm = {
            productId: this.id,
            minValue: this.minValue,
        }
        this.alarmService.add(alarmInfo).then((item) => {
            if (item.errorMsg !== undefined) {
                this.errorMsg = item.errorMsg
            } else {
                if (item.data) {
                    this.alarmStore.add(item.data)
                }
                router.push('/watchlist')
            }
        })
    }

    get isAuthenticated (): boolean {
        return this.identityService.isAuthenticated()
    }

    getProduct () {
        return this.productStore.$state.product
    }
}
