
import { CustomerService } from '@/bll/service/CustomerService'
import { useCustomerStore } from '@/stores/customer'
import { Options, Vue } from 'vue-class-component'
import Logger from '@/util/logger'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {
        id: String
    },
    emits: [],
})
export default class CustomerDetails extends Vue {
    private logger = new Logger(CustomerDetails.name)
    id!: string
    customersStore = useCustomerStore()
    customerService = new CustomerService()

    async mounted (): Promise<void> {
        this.logger.info('mounted')
        // this.customersStore.$state.customer =
        //     await this.customerService.get(this.id)
    }
}

