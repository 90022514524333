
import { Options, Vue } from 'vue-class-component'
import { useFeatureStore } from '@/stores/feature'
import { FeatureService } from '@/bll/service/FeatureService'
import Logger from '@/util/logger'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {
        id: String
    },
    emits: [],
})
export default class FeatureDetails extends Vue {
    private logger = new Logger(FeatureDetails.name)
    id!: string
    wishesStore = useFeatureStore()
    wishService = new FeatureService()

    async mounted (): Promise<void> {
        this.logger.info('mounted')
        // this.wishesStore.$state.feature =
        //     await this.wishService.get(this.id)
    }
}

