import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-12" }
const _hoisted_3 = {
  key: 0,
  class: "text-danger validation-summary-errors",
  "data-valmsg-summary": "true"
}
const _hoisted_4 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h4", null, "Delete account " + _toDisplayString(_ctx.id), 1),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.errorMsg != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("ul", null, [
                _createElementVNode("li", null, _toDisplayString(_ctx.errorMsg), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("input", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.submitClicked())),
              type: "submit",
              value: "Delete",
              class: "btn btn-primary"
            })
          ])
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_RouterLink, { to: { name: 'accounts' } }, {
        default: _withCtx(() => [
          _createTextVNode("Back to Accounts")
        ]),
        _: 1
      })
    ])
  ], 64))
}