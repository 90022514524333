
import { ProductService } from '@/bll/service/ProductService'
import { useProductStore } from '@/stores/product'
import { Options, Vue } from 'vue-class-component'
import Logger from '@/util/logger'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {
        id: String
    },
    emits: [],
})
export default class ProductDelete extends Vue {
    private logger = new Logger(ProductDelete.name)
    id!: string
    productsStore = useProductStore()
    productService = new ProductService()

    errorMsg: string | null = null

    async submitClicked (): Promise<void> {
        this.logger.info('submitClicked')

        // await this.productService.delete(this.id)

        /*
        if (res.status >= 300) {
            this.errorMsg = res.status + ' ' + res.errorMsg
        } else {
            this.productsStore.$state.products =
                await this.productService.getAll()

            this.$router.push('/products')
        }
         */
    }
}
