
import { Options, Vue } from 'vue-class-component'
import { MetricService } from '@/bll/service/MetricService'
import { useMetricStore } from '@/stores/metric'
import Logger from '@/util/logger'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {
        id: String
    },
    emits: [],
})
export default class MetricDelete extends Vue {
    private logger = new Logger(MetricDelete.name)
    id!: string
    wishesStore = useMetricStore()
    wishService = new MetricService()

    errorMsg: string | null = null

    async submitClicked (): Promise<void> {
        this.logger.info('submitClicked')

        // const res = await this.wishService.delete(this.id);

        /*
        if (res.status >= 300) {
            this.errorMsg = res.status + ' ' + res.errorMsg
        } else {
            this.wishesStore.$state.wishes =
                await this.wishService.getAll()

            this.$router.push('/wishes')
        }
         */
    }
}
