
import { Options, Vue } from 'vue-class-component'
import { PriceService } from '@/bll/service/PriceService'
import { usePriceStore } from '@/stores/price'
import Logger from '@/util/logger'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {},
    emits: [],
})
export default class PriceIndex extends Vue {
    private logger = new Logger(PriceIndex.name)
    wishesStore = usePriceStore()
    wishService = new PriceService()

    async mounted (): Promise<void> {
        this.logger.info('mounted')
        // this.wishesStore.$state.prices =
        //     await this.wishService.getAll()
    }
}

