
import { Options, Vue } from 'vue-class-component'
import { AlarmService } from '@/bll/service/AlarmService'
import { useAlarmStore } from '@/stores/alarm'
import Logger from '@/util/logger'
import { IAlarm } from '@/dal/domain/IAlarm'
import Header from '@/components/Header.vue'
import { IdentityService } from '@/bll/service/IdentityService'
import router from '@/router'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {
        Header
    },
    props: {},
    emits: [],
})
export default class AlarmIndex extends Vue {
    private logger = new Logger(AlarmIndex.name)
    private identityService = new IdentityService()
    alarmStore = useAlarmStore()
    alarmService = new AlarmService()

    errorMsg: string | null = null

    async mounted (): Promise<void> {
        this.logger.info('mounted')
        const items = await this.alarmService.getAll()
        this.alarmStore.$state.alarms = items.data as IAlarm[]
    }

    get isAuthenticated (): boolean {
        return this.identityService.isAuthenticated()
    }

    getAlarm (): IAlarm[] {
        return this.alarmStore.$state.alarms
    }

    addAlarm (productId: number) {
        this.logger.info('addAlarm')
        router.push('/alarm/create/' + productId)
    }

    clickRemove (alarmId: number) {
        this.alarmService.delete(alarmId).then((item) => {
            if (item.errorMsg !== undefined) {
                this.errorMsg = item.errorMsg
            } else {
                if (item.data) {
                    this.alarmStore.remove(item.data)
                }
            }
        })
    }

    getProductImageByBarcode (id: string) {
        return `/images/product/${id}.jpg`
    }
}

