
import { WatchlistService } from '@/bll/service/WatchlistService'
import { useWatchlistStore } from '@/stores/watchlist'
import { Options, Vue } from 'vue-class-component'
import { CustomerService } from '@/bll/service/CustomerService'
import { IWatchlist } from '@/dal/domain/IWatchlist'
import Logger from '@/util/logger'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {},
    emits: [],
})
export default class WatchlistCreate extends Vue {
    private logger = new Logger(WatchlistCreate.name)

    watchlistStore = useWatchlistStore()
    watchlistService = new WatchlistService()
    customerService = new CustomerService()

    errorMsg: string | null = null

    async submitClicked (): Promise<void> {
        this.logger.info('submitClicked')

        const watchlist: IWatchlist = {}
        const res = await this.watchlistService.add(watchlist)

        if (res.status == null || res.status >= 300) {
            this.errorMsg = res.status + ' ' + res.errorMsg
        } else {
            // this.watchlistStore.$state.watchlistes =
            //     await this.watchlistService.getAll()

            this.$router.push('/watchlist')
        }
    }
}
