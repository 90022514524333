
import { Options, Vue } from 'vue-class-component'
import { RoleService } from '@/bll/service/RoleService'
import { useRoleStore } from '@/stores/role'
import Logger from '@/util/logger'
import { IWatchlist } from '@/dal/domain/IWatchlist'

/**
 * @author Ahto Jalak
 * @since 06.02.2023
 */
@Options({
    components: {},
    props: {
        id: Number,
    },
    emits: [],
})
export default class RoleDetails extends Vue {
    id!: number

    private logger = new Logger(RoleDetails.name)

    wishesStore = useRoleStore()
    wishService = new RoleService()

    async mounted (): Promise<void> {
        this.logger.info('mounted')
        const item = await this.wishService.get(this.id)
        this.wishesStore.$state.role = item.data as IWatchlist
    }
}

